import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import Papa from "papaparse";
import { getFirestore } from "firebase/firestore";
import { app, host } from "../../config";
import { v4 as uuidv4 } from "uuid";

import "./lossRatioSystem.css";
import { DataType } from "./lossRatioTableComponent";
import CircularProgress from "@mui/material/CircularProgress";
import LossRatioDataGrid from "./lossRatioTableComponent";

const auth = getAuth();
const db = getFirestore(app);

const LossRatioLookupSystem: React.FC = () => {
  const [groupNumber, setGroupNumber] = useState("");
  const [contractYear, setContractYear] = useState("");
  const [numberOfMonths, setNumberOfMonths] = useState("");
  const [groupId, setGroupId] = useState("");
  const [planId, setPlanId] = useState("");
  const [apiData, setApiData] = useState<Array<DataType>>([]);
  const [showApiData, setShowApiData] = useState(false);
  const [isVBA, setIsVBA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterType, setFilterType] = useState<"contractYear" | "numberOfMonths">("contractYear");

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, role, selectedGroup } = authContext;
  const [isAdmin, setIsAdmin] = useState(() => role === "admin");

  const navigate = useNavigate();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // if (groupNumber === "") {
    //   alert("Please fill in the group number field before submitting!");
    //   return;
    // }
    setIsLoading(false);
    setShowApiData(true);

    if (filterType === "numberOfMonths" && !numberOfMonths) {
      alert("Please enter both start and end months for the range!");
      return;
    }

    let holder: { [key: string]: any } = {
      groupId: groupId,
      planId: planId,
      filterType: filterType,
    };

    if (filterType === "numberOfMonths") {
      holder.numberOfMonths = numberOfMonths;
    }

    setIsLoading(true);

    let params = new URLSearchParams(holder);

    fetch(`${host}/api/upload/lossRatioReport?${params}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        if (!response.ok) {
          await response.text().then((text) => {
            console.log(text);
            alert(`An error has occured with your query:\n${text}`);
          });
          return;
        } else {
          await response.json().then((res) => {
            setIsLoading(false);
            setIsVBA(res.vba);
            setShowApiData(true);
            console.log("this is res");
            console.log(res);
            console.log("this is res");
            console.log(res.data);
            const dataWithUidAndFormattedDates = res.results.map((item) => {
              if (item.Coverage_End) {
                item.Coverage_End = new Date(item.Coverage_End).toLocaleDateString("en-US");
              }
              if (item.Coverage_Start) {
                item.Coverage_Start = new Date(item.Coverage_Start).toLocaleDateString("en-US");
              }
              return {
                ...item,
                uid: uuidv4(),
              };
            });
            setApiData(dataWithUidAndFormattedDates);
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        alert("An error has occured when searching for LossRatio");
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const downloadLossRatio = (data: any) => {
    let dataToDownload: any[] = data.map((item: any) => {
      // Remove unwanted fields
      const { Tax_ID, Provider_Addr1, Provider_City, Provider_State, Provider_Zip, uid, ...filteredItem } = item;
      return filteredItem;
    });

    const timeStamp = new Date()
      .toISOString()
      .replace(/[^0-9]/g, "")
      .slice(0, -3);
    const csv = Papa.unparse(dataToDownload);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    let hiddenElement = document.createElement("a");
    hiddenElement.href = csvUrl;
    hiddenElement.download = `${groupNumber}_LossRatio_${timeStamp}.csv`;
    hiddenElement.click();
  };

  const handleDownloadErrorsCheck = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    downloadLossRatio(apiData);
  };

  const goBack = () => {
    setShowApiData(false);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  if (showApiData) {
    return (
      <div className="App">
        <header className="app-header">
          <div className="header">
            <h1>LossRatio</h1>
          </div>
          <div className="button-container2">
            <button className="LossRatio-back-button" onClick={goBack}>
              Back
            </button>

            {/* <button className="download-claims-data" onClick={(e) => handleDownloadErrorsCheck(e)}>
              Download Claims Information
            </button> */}
          </div>
        </header>
        {isVBA && tk ? (
          <>
            <LossRatioDataGrid dbType="vba" data={apiData} />
          </>
        ) : (
          <>
            <LossRatioDataGrid dbType="wlt" data={apiData} />
          </>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div className="header">
          <h1>Loss Ratio Table</h1>
        </div>
        <form onSubmit={handleSubmit}>
          {/* <label>
            Group Number:
            <input
              type="text"
              value={groupNumber}
              onChange={(e) => setGroupNumber(e.target.value)}
              placeholder="Required"
            />
          </label> */}

          <div className="filter-section">
            <h3>Select Filter Type</h3>
            <div className="filter-type-selection">
              <div className="radio-button-wrapper">
                <input
                  type="radio"
                  id="contractYear"
                  value="contractYear"
                  checked={filterType === "contractYear"}
                  onChange={(e) => setFilterType(e.target.value as "contractYear" | "numberOfMonths")}
                />
                <label htmlFor="contractYear">Contract Year</label>
              </div>
              <div className="radio-button-wrapper">
                <input
                  type="radio"
                  id="numberOfMonths"
                  value="numberOfMonths"
                  checked={filterType === "numberOfMonths"}
                  onChange={(e) => setFilterType(e.target.value as "contractYear" | "numberOfMonths")}
                />
                <label htmlFor="numberOfMonths">Month Range</label>
              </div>
            </div>

            {filterType === "contractYear" ? null : (
              <div className="filter-inputs">
                <div className="month-range-inputs">
                  <div>
                    <label htmlFor="numberOfMonths">Number Of Months to View</label>
                    <input
                      id="numberOfMonths"
                      type="number"
                      value={numberOfMonths}
                      onChange={(e) => setNumberOfMonths(e.target.value)}
                      min="1"
                      max="12"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="optional-ids">
            <label>
              Group ID: (Optional)
              <input type="text" value={groupId} onChange={(e) => setGroupId(e.target.value)} />
            </label>
            <label>
              Plan ID: (Optional)
              <input type="text" value={planId} onChange={(e) => setPlanId(e.target.value)} />
            </label>
          </div>

          <button type="submit">Look up</button>
        </form>
      </div>
    );
  }
};

export default LossRatioLookupSystem;
