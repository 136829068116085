import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import "./MUIstyling.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import { host } from "../../config";

import { Navigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";

export type DataType = {
  uid: string; // Unique identifier
  Claims: number;
  PREMIUM: number;
  LossRatio: number;
  Coverage_Start: string;
  Coverage_End: string;
  GROUP_ID: number;
  plan_id: number;
};

function formatData(input: DataType): DataType {
  return {
    ...input,
    // Add any transformations you need here
  };
}

declare module "@mui/material/styles" {
  interface Components {
    // This should extend the existing MUI theme types with the new `MuiDataGrid` component.
    MuiDataGrid?: {
      styleOverrides?: {
        // Your style overrides here
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white"; // Default color
            "&:hover": {
              color: "white"; // Change to white on hover
            };
          };
        };
      };
    };
  }
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white", // For other icon buttons
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "white",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          color: "black",
        },
        select: {
          color: "black",
        },
        actions: {
          "& .MuiIconButton-root": {
            color: "black", // Ensures the pagination arrows are always black
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
              color: "black", // Keeps the icon black on hover
            },
          },
        },
      },
    },
  },
});

function removeDuplicateLossRatio(data: DataType[]): DataType[] {
  const seen = new Set<number>();
  return data.filter((item) => {
    const duplicate = seen.has(item.plan_id);
    seen.add(item.plan_id);
    return !duplicate;
  });
}

const auth = getAuth();

export const LossRatioDataGrid = (props: { dbType: string; data: DataType[] }) => {
  const [groupNumber, setGroupNumber] = useState("");
  const filteredRows = removeDuplicateLossRatio(props.data.map((entry) => formatData(entry)));

  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<DataType | null>(null);
  // const [eobData, setEobData] = React.useState<EobDataType[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);

  var dbType = props.dbType;

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, role, selectedGroup } = authContext;

  useEffect(() => {
    if (role == "broker" || role == "employer") setGroupNumber(selectedGroup);
  }, [selectedGroup]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const headCells: GridColDef[] = [
    // {
    //   field: "More Info",
    //   headerName: "More Info",
    //   width: 140,
    //   renderCell: (params: GridRenderCellParams<any>) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <strong>{params.value}</strong>
    //       <Button
    //         variant="contained"
    //         size="small"
    //         style={{ marginLeft: "8px", backgroundColor: "#004e9d" }}
    //         onClick={() => handleOpen(params.row)}
    //       >
    //         Member EOB
    //       </Button>
    //     </Box>
    //   ),
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "GROUP_ID",
      headerName: "Group ID",
      width: 230,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "plan_id",
      headerName: "Plan ID",
      width: 230,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "Claims",
      headerName: "Total Claims",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "PREMIUM",
      headerName: "Total Premiums",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "LossRatio",
      headerName: "Loss Ratio",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "Coverage_Start",
      headerName: "Coverage Start",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Coverage_End",
      headerName: "Coverage End",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
  ];

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 650,
          width: "89%", // Updated to 100%
          backgroundColor: "#f8f8f8",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
          padding: "20px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: "#004e9d",
            color: "#ffffff",
            padding: "10px 20px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.2)", // Enhanced shadow
            fontWeight: "bold",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #e0e0e0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            color: "#333",
          },
        }}
      >
        <DataGrid
          sx={{
            height: "auto", // Allows DataGrid to grow with content up to the Box height
            width: "100%", // Ensures DataGrid uses full width of the Box, considering padding
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important", // Adjusts the header alignment issue
            },
          }}
          rows={filteredRows}
          columns={headCells}
          getRowId={(filteredRows) => filteredRows.uid}
          disableColumnSelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          slots={{ toolbar: GridToolbar }}
          disableRowSelectionOnClick
        />
      </Box>

      {/* <RowDetailsDialog open={open} onClose={handleClose} selectedRow={selectedRow} eobData={eobData} /> */}
    </ThemeProvider>
  );
};

export default LossRatioDataGrid;
